import { ConfirmationDialog, OverflowMenu } from 'src/design-system'
import * as React from 'react'

export type OpenRolesResource = { slug: string; type: string }

export type OpenRolesOverflowProps = {
  positionId: number | string
  positionName: string
  resource: OpenRolesResource
  hiringLink?: string | null
  showOpenHiringLink?: boolean
}

export const OpenRolesOverflow = (props: OpenRolesOverflowProps) => {
  const { positionId, resource, positionName, hiringLink, showOpenHiringLink } =
    props

  const onEdit = () => {
    window.Rails.ajax({
      dataType: 'script',
      url: `/${resource.type}/${resource.slug}/open_roles/${positionId}/edit`,
      type: 'GET',
    })
  }

  const onDestroy = () => {
    window.Rails.ajax({
      dataType: 'script',
      url: `/${resource.type}/${resource.slug}/open_roles/${positionId}`,
      type: 'DELETE',
    })
  }

  return (
    <ConfirmationDialog.Root
      title="Are you sure?"
      body={`This will remove the hiring link from ${positionName}.`}
      onConfirm={onDestroy}
    >
      <OverflowMenu.Root right>
        <OverflowMenu.Item onClick={onEdit}>Edit</OverflowMenu.Item>
        {showOpenHiringLink && hiringLink && (
          <OverflowMenu.Link
            isExternal
            className="text-gray-900"
            href={hiringLink}
          >
            Open hiring link
          </OverflowMenu.Link>
        )}
        <ConfirmationDialog.Trigger asChild>
          <OverflowMenu.Item variant="destructive">Delete</OverflowMenu.Item>
        </ConfirmationDialog.Trigger>
      </OverflowMenu.Root>
    </ConfirmationDialog.Root>
  )
}
